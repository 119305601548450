@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Poppins:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
}

/* 1st page */

.banner-bg {
  background-image: url(../assets/Image/landing-page.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.nav-link {
  color: white;
  margin-right: 20px;
  font-weight: 600;
}
#Landging-page .landing-header .page-logo img {
  width: 70%;
}

#Landging-page .nav-link {
  color: white;
  font-weight: bold;
}

#Landging-page .nav-link:hover {
  color: #e7b900;
}

#Landging-page nav {
  float: right;
  padding-right: 25px;
}

#Landging-page nav ul li {
  margin-left: 15px;
}

#Landging-page .product-dropdown ul li {
  list-style: none;
}

#Landging-page span {
  margin-left: 10px;
}

#Landging-page .product-dropdown ul li a {
  text-decoration: none;
  font-weight: bold;
}

#Landging-page .product-dropdown {
  display: none;
}

#Landging-page ul li:hover .product-dropdown {
  display: block;
  position: absolute;
  z-index: 99;
}

#banner-text .container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

#page-2 .container .row {
  min-height: 100vh;
}

#banner-text .page-title {
  margin: auto;
  width: 100%;
  padding: 10px;
}

#banner-text .page-title .h1-large {
  color: white;
  width: fit;
  background-color: #e7b900;
  padding: 5px 30px;
  margin-right: auto;
  margin-left: auto;
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  line-height: 4rem;
  text-transform: uppercase;
  clip-path: polygon(0 100%, 16px 0, calc(100% - 16px) 0, 100% 100%);
}

@media (max-width: 768px) {
  #banner-text .page-title .h1-large {
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding: 5px 15px;
  }
  #banner-text .page-title .h2-large {
    font-size: 1.5rem !important;
    line-height: 2.5rem;
    padding: 5px 15px;
  }
}

#banner-text .page-title .h2-large {
  background-color: #e7b900;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding: 5px 25px;
  font-size: 4rem;
  color: white;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  clip-path: polygon(0 100%, 16px 0, calc(100% - 16px) 0, 100% 100%);
}

/* @media (max-width: 776){
  #banner-text .page-title .h2-large {
    background-color: #e7b900;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    padding: 5px 25px;
    font-size: 4rem;
    color: white;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    clip-path: none;
  }
} */

#banner-text .page-title .main-title {
  background-color: #e7b900;
}

#banner-text .small-text-1 {
  color: white;
  font-family: "Caveat", cursive;
  font-size: 4rem;
}

#banner-text .small-text-2 {
  color: white;
  text-align: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}

.banner-content p {
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
}

/* 2nd page */

#page-content .banner-2nd-page {
  background-image: url(../assets/Image/2nd-page.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

#page-content .banner-2nd-page .content-details {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.main-content {
  color: black;
  background-image: url(../assets/Image/2nd-page-Shape.png);
  clip-path: polygon(0 100%, 16px 0, calc(100% - 16px) 0, 100% 100%);
}

#page-content .page-details {
  padding: 15px 60px;
}
.main-content p {
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: center;
}

/* 3rd Page */

#banner-3rd-page .bg {
  background-image: url(../assets/Image/3rd-page.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

#banner-3rd-page .content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  min-height: 100vh;
}

#banner-3rd-page h1 {
  color: white;
  text-align: center;
  display: inline-block;
  background-image: url(../assets/Image/2nd-page-Shape.png);
  padding: 10px 20px 10px 50px;
}

#banner-3rd-page span {
  display: block;
  color: white;
  font-size: 20px;
}

/* 4th page */

.bg-4th-page {
  background-image: url(../assets/Image/4th-page.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-4th-page {
  height: 100vh;
  color: white;
  font-family: "Poppins", sans-serif;
}

#product-page .product-page-title {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: end;
}

#product-page h1 span {
  clip-path: polygon(0 100%, 16px 0, calc(100% - 16px) 0, 100% 100%);
  padding: 15px 25px;
  background-color: #890000;
}

#product-page ul {
  list-style: none;
  text-align: center;
}

#product-page li {
  display: inline-block;
  padding: 10px 50px;
}

#product-page a {
  text-decoration: none;
  padding-bottom: 20px;
  color: white;
  display: block;
  font-size: 22px;
}
#product-page a:hover {
  color: #e7b900;
}

/* Footer */

#footer {
  color: white;
  font-size: 14px;
}

#footer .footer-top {
  padding: 80px 0 30px 0;
  background-image: url(../assets/Image/4th-page.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #222222;
}

#footer .footer-top .social-links {
  margin-top: 25px;
}

#footer .footer-top .social-links a {
  text-align: center;
  background: #474545;
  padding: 10px;
  border-radius: 5px;
  margin-right: 4px;
  color: white;
  font-size: 18px;
}

#footer .footer-top .social-links a:hover {
  color: black;
  background-color: #ffc451;
}

#footer .footer-links ul {
  padding: 0;
}

@media (max-width: 759px) {
  #footer .footer-links {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  }
}

#footer .footer-links ul li {
  list-style: none;
  padding-top: 10px;
}

#footer .footer-links ul li a {
  text-decoration: none;
  color: white;
  padding: 10px 0;
}

#footer .footer-links ul li a:hover {
  margin-left: 15px;
  color: #ffc451;
}

#footer .footer-links ul li i {
  color: #ffc451;
  padding-right: 10px;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  position: relative;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  width: 70%;
  border: 0;
  padding: 10px;
  border-radius: 5px 0 0 5px;
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  border: 0;
  background: #ffc451;
  padding: 10px;
  border-radius: 0 5px 5px 0;
}

#footer .footer-bottom {
  background: black;
  padding: 20px 0 20px 0;
}

#footer .footer-bottom .copyright {
  text-align: center;
}

#footer .footer-bottom .credits {
  text-align: center;
  margin-top: 15px;
}

#footer .footer-bottom .credits a {
  color: #ffc451;
  text-decoration: none;
}

.footer_icon {
  font-weight: 900 !important;
  margin-right: 5px;
}

/* Blend-masalas */

#blend-masalas .blend-masalas-bg {
  background-image: url(../assets/Blend-masalas-images/top-view-spoons-with-various-pepper-spices-black-surface_176474-688.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#blend-masalas .blend-page-title {
  color: white;
  font-family: "Caveat", cursive;
  padding: 10px;
  font-size: 30px;
  margin-bottom: 20px;
}

#blend-masalas .blend-page-title h2 {
  font-size: 3rem;
  background-color: #e7b900;
  clip-path: polygon(0 100%, 16px 0, calc(100% - 16px) 0, 100% 100%);
  display: inline;
  padding: 0 15px;
  font-weight: bold;
}

@media (max-width: 557px) {
  #blend-masalas .blend-page-title h2 {
    clip-path: none !important;
  }
}

@media (max-width: 557px) {
  #blend-masalas .blend-page-title h2 {
    font-size: 1rem;
    background-color: #e7b900;
    clip-path: polygon(0 100%, 13px 0, calc(100% - 13px) 0, 100% 100%);
    display: inline;
    padding: 0 15px;
    font-weight: bold;
  }
}

.modal-content {
  background-color: #141414;
  max-width: 400px;
  color: #d05104;
  border: 2px solid #ffffff7b;
}

.modal-header {
  border: none !important;
}

.btn-close {
  background-color: #d05104;
}

.react-multiple-carousel__arrow {
  background-color: #d05104 !important;
}

/* Hovering Effect */

#blend-masalas .blend-masalas-content {
  position: relative;
}

#blend-masalas .blend-masalas-content img {
  width: 100%;
  height: auto;
}

#blend-masalas .blend-masalas-content .secondary-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.7s ease;
}

#blend-masalas .blend-masalas-content .secondary-image:hover {
  opacity: 1;
}

#blend-masalas .blend-masalas-content:hover h5 {
  color: #e7b900;
}

#blend-masalas .blend-masalas-content h5 {
  font-size: 1.5rem;
}

#blend-masalas .blend-masalas-content {
  background: transparent;
  border: 0;
}

#blend-masalas .card-body {
  padding: 10px 0;
  color: white;
}

#blend-masalas .card-body a {
  color: black;
}

#blend-masalas .card-title {
  text-align: center;
}

#blend-masalas .btn-primary {
  display: block;
  background-color: #ffc451;
  border: 0;
}

#blend-masalas .about-us p {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
}

/* Contact Us  */

.contact_address_p {
  color: aliceblue;
}
